import React, { Component } from 'react';
import { FacebookProvider, Page } from 'react-facebook';

export default class FeedFB extends Component {
  render() {
    return (
      <FacebookProvider appId="187014659039177">
        <Page href="https://www.facebook.com/balticseadiving" tabs="timeline" />
      </FacebookProvider>
    );
  }
}