import React from "react"
import { Link } from "gatsby"
import { window } from "browser-monads"
import logo from "../../images/logo-name-2-medium-inverted.png"
import "./nav.css"

const lang = {
  en: {
    diveSites: "Dive sites",
    booking: "Booking",
    news: "News",
    contact: "Contact",
  },
  sv: {
    diveSites: "Dykmål",
    booking: "Bokning",
    news: "Nyheter",
    contact: "Kontakt",
  },
}

const Nav = () => (
  <nav>
    <div className="nav__items">
      <a className="nav__item--left" href="/">
        <img
          src={logo}
          alt="baltic sea diving logo"
          className="nav__item--logo"
        />
      </a>
      <Link
        className={
          window.location.href.indexOf("dive-sites") > 0 ||
          window.location.href.indexOf("category") > 0
            ? "nav__item--link active"
            : "nav__item--link"
        }
        to="/dive-sites"
      >
        {lang.en.diveSites}
      </Link>
      <Link
        className={
          window.location.href.indexOf("booking") > 0 ||
          window.location.href.indexOf("category") > 0
            ? "nav__item--link active"
            : "nav__item--link"
        }
        to="/booking"
      >
        {lang.en.booking}
      </Link>
      {/* //TODO: REMOVE until decided what to do */}
      {/* <Link
        className={window.location.href.indexOf('news') > 0 || window.location.href.indexOf('category') > 0 ? 'nav__item--link active' : 'nav__item--link'}
        to='/news'
      >
        {lang.en.news}
      </Link> */}
      <Link
        className={
          window.location.href.indexOf("contact") > 0
            ? "nav__item--link active"
            : "nav__item--link"
        }
        to="/contact"
      >
        {lang.en.contact}
      </Link>
    </div>
  </nav>
)

export default Nav
